#reset-password-mobile-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 80vh;
}

.reset-password-mobile-password-field {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.reset-password-mobile-password-field input {
	width: 250px;
	height: 30px;
	padding: 0 10px;
	margin: 15px 0;
}

.reset-password-mobile-error-message {
	width: 260px;
	margin-top: 10px;
	color: red;
}

.reset-password-mobile-container-header {
	margin-bottom: 15px;
}

.reset-password-mobile-container-submit-button-disable {
	margin-top: 40px;
	box-shadow: 0px 10px 14px -7px #3e7327;
	background: linear-gradient(to bottom, grey 5%, grey 100%);
	background-color: grey;
	border-radius: 4px;
	border: 1px solid grey;
	display: inline-block;
	cursor: pointer;
	color: #ffffff;
	font-family: Arial;
	font-size: 16px;
	font-weight: bold;
	padding: 6px 12px;
	text-decoration: none;
	text-shadow: 0px 1px 0px grey;
	width: 140px;
	text-align: center;
}

.reset-password-mobile-container-submit-button {
	margin-top: 40px;
	box-shadow: 0px 10px 14px -7px #3e7327;
	background: linear-gradient(to bottom, #77b55a 5%, #72b352 100%);
	background-color: #77b55a;
	border-radius: 4px;
	border: 1px solid #4b8f29;
	display: inline-block;
	cursor: pointer;
	color: #ffffff;
	font-family: Arial;
	font-size: 16px;
	font-weight: bold;
	padding: 6px 12px;
	text-decoration: none;
	text-shadow: 0px 1px 0px #5b8a3c;
	width: 140px;
	text-align: center;
}

.reset-password-mobile-container-submit-button:hover {
	background: linear-gradient(to bottom, #72b352 5%, #77b55a 100%);
	background-color: #72b352;
}

.reset-password-mobile-container-submit-button:active {
	position: relative;
	top: 1px;
}

#reset-password-mobile-container-success {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 80vh;
}
